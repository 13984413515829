/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useContext, useEffect, useState } from "react";
import { Database } from "../../model/database";
import { Table } from "../../model/database/lib/table";
import { DatabaseContext } from "./database-context";
import { Result } from "./result";

/**
 * データベース オブジェクトを取得するフックです。
 */
export function useDatabase(): Database {
    const db = useContext(DatabaseContext);
    if (db == null) {
        throw new Error("Database not found.");
    }
    return db;
}

/**
 * `table.get(id, options)` メソッドを利用してデータを取得するフックです。
 * @param tableId テーブル名
 * @param id 取得するレコードの ID
 * @param options オプション
 */
export function useDatabaseGet<T extends useDatabaseGet.TableId>(
    tableId: T,
    id: string,
    { disabled = false }: useDatabaseGet.Options = {},
): Result<useDatabaseGet.Output<T>> {
    const table = useDatabase()[tableId];
    const revision = useUpdateRevision(table);
    const [result, setResult] = useState<Result<useDatabaseGet.Output<T>>>({});

    // データ取得処理
    useEffect(() => {
        if (disabled) {
            return undefined;
        }
        const ac = new AbortController();

        // 次のバグ (TypeScript 4.2 で修正) のため、キャストが必要だった。
        // https://github.com/microsoft/TypeScript/issues/36390
        (
            table.get(id, { signal: ac.signal }) as Promise<
                useDatabaseGet.Output<T>
            >
        ).then(
            (data: useDatabaseGet.Output<T>) => {
                if (!ac.signal.aborted) {
                    setResult({ data });
                }
            },
            (error: unknown) => {
                if (!ac.signal.aborted) {
                    setResult({ error });
                }
            },
        );

        return () => {
            ac.abort();
        };
    }, [disabled, id, revision, table]);

    return result;
}
export namespace useDatabaseGet {
    /**
     * `get(id, options)` メソッドを利用できるテーブルの種別
     */
    export type TableId = {
        [P in keyof Database]: Database[P] extends {
            get(id: string, options: Table.GetOptions): Promise<unknown>;
        }
            ? P
            : never;
    }[keyof Database];

    /**
     * 結果の型
     */
    export type Output<T extends TableId> = Database[T] extends {
        get(id: string, options: Table.GetOptions): Promise<infer U>;
    }
        ? U
        : never;

    /**
     * オプション
     */
    export type Options = {
        /**
         * 何もしない
         */
        disabled?: boolean;
    };
}

/**
 * `table.find(input, options)` メソッドを利用してデータを取得するフックです。
 * @param tableId テーブル名
 * @param input 検索条件
 * @param options オプション
 */
export function useDatabaseFind<T extends useDatabaseFind.TableId>(
    tableId: T,
    input?: useDatabaseFind.Input<T>,
    { disabled = false }: useDatabaseFind.Options = {},
): Result<useDatabaseFind.Output<T>> {
    const table = useDatabase()[tableId];
    const revision = useUpdateRevision(table);
    const [result, setResult] = useState<Result<useDatabaseFind.Output<T>>>({});
    const inputStr = JSON.stringify(input);

    // データ取得処理
    useEffect(() => {
        if (disabled) {
            return undefined;
        }
        const ac = new AbortController();

        // データ取得中を通知できるように、データをクリアする
        setResult({ data: undefined });

        // 次のバグ (TypeScript 4.2 で修正) のため、キャストが必要だった。
        // https://github.com/microsoft/TypeScript/issues/36390
        (
            (table as any).find(input as any, { signal: ac.signal }) as Promise<
                useDatabaseFind.Output<T>
            >
        ).then(
            (data: useDatabaseFind.Output<T>) => {
                if (!ac.signal.aborted) {
                    setResult({ data });
                }
            },
            (error: unknown) => {
                if (!ac.signal.aborted) {
                    setResult({ error });
                }
            },
        );

        return () => {
            ac.abort();
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps -- input の代わりに inputStr を使う
    }, [disabled, inputStr, revision, table]);

    return result;
}
export namespace useDatabaseFind {
    /**
     * `find(input, options)` メソッドを利用できるテーブルの種別
     */
    export type TableId = {
        [P in keyof Database]: Database[P] extends {
            find(input: unknown, options: Table.FindOptions): Promise<unknown>;
        }
            ? P
            : never;
    }[keyof Database];

    /**
     * 入力の型
     */
    export type Input<T extends TableId> = Database[T] extends {
        find(input: infer U, options: Table.FindOptions): Promise<unknown>;
    }
        ? U
        : never;

    /**
     * 結果の型
     */
    export type Output<T extends TableId> = Database[T] extends {
        find(input: unknown, options: Table.FindOptions): Promise<infer U>;
    }
        ? U
        : never;

    /**
     * オプション
     */
    export type Options = {
        /**
         * 何もしないフラグ。
         * 他の非同期処理の結果を利用して検索したい場合、他の非同期処理が完了するまで `true` にすると良い。
         */
        disabled?: boolean;
    };
}

/**
 * `table.findGreedily(input, options)` メソッドを利用してデータを取得するフックです。
 * @param tableId テーブル名
 * @param input 検索条件
 * @param options オプション
 */
export function useDatabaseFindGreedily<
    T extends useDatabaseFindGreedily.TableId,
>(
    tableId: T,
    input?: useDatabaseFindGreedily.Input<T>,
    { disabled = false }: useDatabaseFindGreedily.Options = {},
): Result<useDatabaseFindGreedily.Output<T>> {
    const table = useDatabase()[tableId];
    const revision = useUpdateRevision(table);
    const [result, setResult] = useState<
        Result<useDatabaseFindGreedily.Output<T>>
    >({});
    const inputStr = JSON.stringify(input);

    // データ取得処理
    useEffect(() => {
        if (disabled) {
            return undefined;
        }
        const ac = new AbortController();

        // 次のバグ (TypeScript 4.2 で修正) のため、キャストが必要だった。
        // https://github.com/microsoft/TypeScript/issues/36390
        (
            (table as any).findGreedily(input as any, {
                signal: ac.signal,
            }) as Promise<useDatabaseFindGreedily.Output<T>>
        ).then(
            (data: useDatabaseFindGreedily.Output<T>) => {
                if (!ac.signal.aborted) {
                    setResult({ data });
                }
            },
            (error: unknown) => {
                if (!ac.signal.aborted) {
                    setResult({ error });
                }
            },
        );

        return () => {
            ac.abort();
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps -- input の代わりに inputStr を使う
    }, [disabled, inputStr, revision, table]);

    return result;
}
export namespace useDatabaseFindGreedily {
    /**
     * `find(input, options)` メソッドを利用できるテーブルの種別
     */
    export type TableId = {
        [P in keyof Database]: Database[P] extends {
            findGreedily(
                input: unknown,
                options: Table.FindOptions,
            ): Promise<unknown>;
        }
            ? P
            : never;
    }[keyof Database];

    /**
     * 入力の型
     */
    export type Input<T extends TableId> = Database[T] extends {
        findGreedily(
            input: infer U,
            options: Table.FindOptions,
        ): Promise<unknown>;
    }
        ? U
        : never;

    /**
     * 結果の型
     */
    export type Output<T extends TableId> = Database[T] extends {
        findGreedily(
            input: unknown,
            options: Table.FindOptions,
        ): Promise<infer U>;
    }
        ? U
        : never;

    /**
     * オプション
     */
    export type Options = {
        /**
         * 何もしないフラグ。
         * 他の非同期処理の結果を利用して検索したい場合、他の非同期処理が完了するまで `true` にすると良い。
         */
        disabled?: boolean;
    };
}

/**
 * 変更イベントの度にインクリメントされる値を生成するフックです。
 * @param table 監視するテーブル
 */
export function useUpdateRevision(
    table: EventTarget,
    disabled = false,
): number {
    const [revision, setRevision] = useState(0);

    useEffect(() => {
        function onUpdate(): void {
            setRevision((r) => (r + 1) & 0x7fffffff);
        }

        if (disabled) {
            return undefined;
        }

        table.addEventListener("update", onUpdate);
        return () => {
            table.removeEventListener("update", onUpdate);
        };
    }, [disabled, table]);

    return revision;
}
